import React, {Component} from "react";
import {navigate} from "gatsby";

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        if(typeof window !== `undefined`) {
            navigate("/");
        }
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}